var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "app-container invoice-record-component" },
    [
      _c("div", { staticClass: "filter-line" }, [
        _c(
          "span",
          [
            _c(
              "el-button",
              {
                attrs: { icon: "el-icon-refresh-right" },
                on: { click: _vm.getTableList },
              },
              [_vm._v(_vm._s(this.$t("commons.refresh")))]
            ),
            _c(
              "el-button",
              { attrs: { type: "primary" }, on: { click: _vm.exportHandler } },
              [_vm._v(_vm._s(this.$t("commons.export")))]
            ),
          ],
          1
        ),
        _c(
          "div",
          {
            staticClass: "header-filter-button",
            class: _vm.dataset.filterLine.show
              ? "filter-button-open"
              : "filter-button-close",
            on: {
              click: function ($event) {
                _vm.dataset.filterLine.show = !_vm.dataset.filterLine.show
              },
            },
          },
          [
            _c("i", { staticClass: "iconfont icon-loudoutu" }),
            _vm._v("\n      筛选\n    "),
          ]
        ),
      ]),
      _c(
        "div",
        [
          _c("finalTable", {
            ref: "finalTableRef",
            attrs: { datas: _vm.dataset },
            on: { tableEventHandler: _vm.tableEventHandler },
            scopedSlots: _vm._u([
              {
                key: "projectIdSlot",
                fn: function (row) {
                  return [
                    _c("span", [_vm._v(" " + _vm._s(row.datas.projectName))]),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.detailDialogShow,
            width: "728px",
            "destroy-on-close": true,
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.detailDialogShow = $event
            },
          },
        },
        [
          _c("template", { slot: "title" }, [_c("span", [_vm._v("发票详情")])]),
          _vm.detailDialogShow
            ? _c("invoiceDetail", {
                attrs: { dataset: _vm.invoiceDetails },
                on: {
                  close: function ($event) {
                    _vm.detailDialogShow = false
                  },
                },
              })
            : _vm._e(),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }