import request from '@/utils/request';
import { envInfo } from '@/constants/envInfo';

/**
 * 发票记录 - 分页查询
 * @param params
 */
export function getInvoiceList(params) {
  return request({
    url: envInfo.bgApp.customerlegoInvoicePath + '/invoiceManage/queryInvoiceList',
    method: 'get',
    params,
  });
}

/**
 * 发票记录 - 导出
 * @param params
 */
export function invoiceListExport(params) {
  return request({
    url: envInfo.bgApp.customerlegoInvoicePath + '/invoiceManage/export',
    method: 'get',
    params,
  });
}
